<template>
  <v-main class="d-flex justify-center align-center">
    <v-container>
      <v-card-title>{{ $t('pages.notfound.title') }}</v-card-title>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'PageNotFound',
    created () {
      window.document.title = '404 not found'
    },
  }
</script>
